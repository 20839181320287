/* ==============================
    Basic/generic global styles
 ============================== */

.lazyload,
.lazyloading {
    opacity: 0;
    transform: translateZ(0);
}

.lazyloaded {
    opacity: 1;
    transition: opacity 1s linear;
}

.no-js {
    img.lazyload {
        display: none;
    }
}

.disable-animations * {
    transition-duration: 0s !important;
}

[data-sticky-wrapper] {
    will-change: min-height;
}

[data-sticky-item] {
    transform: translate3d(0, 0, 0);
    will-change: position, transform;
}

.no-visible-outline {
    button, a, input, label, textarea, select, option {
        outline: none !important;
    }
}


/* clears the ‘X’ from Internet Explorer */
input[type=search]::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}

input[type=search]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}
