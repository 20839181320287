/** Tailwind **/
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/** Fonts, includes, Værsågod reset **/
@import './base/fonts.pcss';
@import './base/reset.pcss';

/** Custom Tailwind components and utilities */
@import './tailwind/components.pcss';
@import './tailwind/utilities.pcss';

/** Misc styles **/
@import './styles/html.pcss';
@import './styles/wysiwyg.pcss';
//@import './styles/flickity.pcss';

/** BEM **/
@import './bem/page-footer.pcss';
@import './bem/page-header.pcss';
@import './bem/content-block.pcss';
@import './bem/article/article-share-area.pcss';
@import './bem/menu.pcss';
@import './bem/sticky-teaser.pcss';
@import './bem/flash-message.pcss';
@import './bem/enupal.pcss';
