.content-block {
    &.-text {
        margin-top: 1.75em;
        margin-bottom: 1.75em;
    }
    
    &.-video,
    &.-storypanels,
    &.-textandimage,    
    &.-adbanner {
        margin-top: 40px;
        margin-bottom: 40px;
        
        @screen m {
            margin-top: 60px;
            margin-bottom: 60px;
        }
        
        @screen l {
            margin-top: 80px;
            margin-bottom: 80px;
        }
        
        @media print {
            display: none;
        }
    }
    
    &.-images {
        margin-top: 40px;
        margin-bottom: 40px;
        
        @media print {
            page-break-before: auto; 
            page-break-after: auto;
            page-break-inside: avoid;
        }
        
        @screen m {
            margin-top: 40px;
            margin-bottom: 0;
        }
        
        &+.content-block.-images {
            margin-top: 0;
        }
    }
    
    /*
    &.-textandimage {
        margin-top: 30px;
        margin-bottom: 30px;
        
        @screen m {
            margin-top: 40px;
            margin-bottom: 40px;
        }
    }
     */
}
