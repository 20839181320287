@layer components {
    /**
    ========================
    Custom Tailwind components
    https://tailwindcss.com/docs/extracting-components
    ========================
     */
    .link,
    .link-alt,
    .link-white,
    .link-black {
        @apply border-b-1 hover:border-opacity-30;
        transition: border-color 0.1s linear;
    }

    .link {
        @apply border-green text-green dark:border-green-bright dark:text-green-bright;
    }

    .link-alt {
        @apply border-black text-black dark:text-grey-medium dark:border-grey-medium;
    }

    .link-black {
        @apply border-black text-black;
    }

    .link-white {
        @apply border-white text-white;
    }

    .list-bar {
        &:after {
            content: '|';
            padding-left: 3px;
        }
    }

    .top-padding {
        padding-top: 150px;

        @screen m {
            padding-top: 180px;
        }
    }

    .h1 {
        @apply font-quarto font-black text-36 leading-1_1 sp:text-52 l:text-64;
    }

    .h1-alt {
    // portrait header
        @apply font-quarto font-black text-36 leading-1_1 sp:text-52 m:text-40 mp:text-52 l:text-64;
    }

    .h2 {
        @apply font-quarto font-black text-22 leading-1_1 mp:text-36;
    }

    .h3 {
        @apply font-medium text-18 leading-1_5 m:text-22;
    }

    .summary {
        @apply font-medium text-16 l:text-18 tracking-1px;

        b, strong {
            @apply font-medium;
        }
    }

    .quote {
        @apply font-quarto text-24 l:text-32 font-black leading-1_3;
    }

    .text-label {
        @apply uppercase text-13 leading-1_5 m:text-15 m:leading-1_7 tracking-1px;
    }

    .sidebar-text {
        @apply text-13 leading-1_5 m:text-15 m:leading-1_7;
    }

    .teaser-text {
        @apply text-13 leading-1_5 m:text-15 m:leading-1_7 l:text-16;
    }

    .small-text {
        @apply text-12 leading-1_5 m:text-14 m:leading-1_7;
    }

    .caption {
        @apply italic text-12 leading-1_5 m:text-14 m:leading-1_7;
    }

    .mix-blend-multiply {
        mix-blend-mode: multiply;
    }

    .mix-blend-screen {
        mix-blend-mode: screen;
    }

    .oversized-140 {
        width: 140%;
        height: 140%;
        left: -20%;
        top: -20%;
    }

    .spinner::after {
        content: '';
        box-sizing: border-box;
        width: 20px;
        height: 20px;
        position: absolute;
        top: calc(50% - 10px);
        left: calc(50% - 10px);
        border-radius: 50%;
    }

    .spinner.material::after {
        border-top: 3px solid currentColor;
        border-left: 3px solid currentColor;
        border-bottom: 3px solid currentColor;
        border-right: 3px solid rgba(255, 255, 255, 0.0);
        animation: spinner .6s linear infinite;
    }

    @keyframes spinner {
        to {
            transform: rotate(360deg);
        }
    }
    
    .wide-header-image-wrap {
        padding-bottom: calc((4/3) * 100%);

        @screen m {
            padding-bottom: calc((2/3) * 100%);
        }

        @screen l {
            padding-bottom: calc((9/16) * 100%);
        }
    }
    
    .inline-content-column {
        @screen s {
            width: 140px;
            float: right;
            margin: 6px 0 20px 20px;
        }

        @screen sp {
            width: 180px;
        }

        @screen m {
            width: 240px;
            margin: 5px 0 30px 30px;
        }

        @screen mp {
            width: 240px;
            margin: 5px -50px 30px 30px;
        }

        @screen l {
            width: 260px;
            margin: 5px -100px 40px 40px;
        }
    }
    
    .most-read-bg-square {
        width: 110vw;
        height: 110vw;
        border: 25px solid;
        @apply border-green-bright;
        left: 50%;
        top: 20px;
        transform-origin: 0 0;
        transform: rotate(45deg);
        z-index: 0;
        
        @screen m {
            top: 40px;
        }
        
        .dark & {
            border-color: #333333;

        }
    }
    
    .content-blur {
        filter: blur(3px);
    }
    
    .lock-background {
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0.7) 70%, rgba(255, 255, 255, 1) 100%);
    
        .dark & {
            background: linear-gradient(to bottom, rgba(20, 20, 20, 0.7) 70%, rgba(20, 20, 20, 1) 100%);
        }
    }
    
    .variable-intrinsic {
        padding-bottom: var(--intrinsic-ratio-base);
        
        @screen m {
            padding-bottom: var(--intrinsic-ratio-m, var(--intrinsic-ratio-base, 100%));
        }
    }
    
    .box-shadow {
        box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
    }
    
    .default-select {
        appearance: none;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right 10px center;
        background-size: 1em;
        
        .dark & {
            background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
        }
    }
}
