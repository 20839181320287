.enupal-stripe-button {
    color: #141414 !important;
    background: transparent none !important;
    border-radius: 0 !important;
    padding: 10px !important;
    border: 1px solid currentColor !important;
    
    .dark & {
        color: #ccc  !important;
    }
    
    span {
        background: none !important;
        box-shadow: none !important;
        color: inherit !important;
    }
}
