.page-header {

    &__bar {
        transition: transform 0.4s;
        @apply ease-out-quart;

        .is-hidden & {
            transform: translateY(-61px);

            @screen m {
                transform: translateY(-81px);
            }
        }

        opacity: 0;

        .no-js &,
        .js-error & {
            opacity: 1;
        }
    }

    &__payoff {
        top: 40px;
        left: 50%;
        transform: translateX(-50%);
        transition-property: opacity;
        transition-duration: 0.1s;
        transition-delay: 0s;
        transition-timing-function: linear;

        @screen m {
            top: 47px;
        }

        .is-collapsed & {
            opacity: 0;
            pointer-events: none;
            transition-delay: 0s;
        }

    }

    &__logo {
        width: 90px;
        height: 20px;
        left: calc(50% - 45px);
        top: 15px;

        @screen m {
            width: 110px;
            height: 24px;
            left: calc(50% - 55px);
            top: 20px;
        }

        transition-property: width, height, left, top;
        transition-duration: 0.3s;
        @apply ease-out-quart;

        .is-collapsed & {
            width: 90px;
            height: 20px;
            left: calc(50% - 45px);
            top: 15px;

            @screen m {
                width: 110px;
                height: 24px;
                left: calc(50% - 55px);
                top: 25px;
            }
        }
    }

    &__logo-symbol {

    }

    &__logo-text {
        transition-property: opacity;
        transition-duration: 0.1s;
        transition-delay: 0s;
        transition-timing-function: linear;
    }

    &__bar-inner {
        height: 70px;
        transition-property: height;
        transition-duration: 0.3s;
        @apply ease-out-quart;

        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .dark & {
            border-bottom: 1px solid #333333;
        }

        @screen m {
            height: 86px;
        }

        .is-collapsed & {
            height: 50px;

            @screen m {
                height: 75px;
            }
        }
    }

    &__bar-inner-padder {
        padding-top: 15px;
        transition-property: padding-top;
        transition-duration: 0.3s;

        @apply ease-out-quart;

        @screen m {
            padding-top: 24px;
        }

        .is-collapsed & {
            padding-top: 6px;

            @screen m {
                padding-top: 19px;
            }
        }
    }

    &__btn {
        display: block;
        position: absolute;
        width: 40px;
        height: 40px;
        top: 0;

        @media print {
            display: none;
        }

        &.-menu {
            left: -7px;

            @screen m {
                left: -3px;
            }

            span {
                display: block;
                position: absolute;
                width: 26px;
                height: 3px;
                left: 7px;
                background: currentColor;

                @screen m {
                    width: 34px;
                    height: 4px;
                    left: 3px;
                }
            }

            span:nth-child(1) {
                top: 10px;
            }

            span:nth-child(2) {
                top: 18px;
            }

            span:nth-child(3) {
                top: 26px;
            }

            @screen m {
                span:nth-child(1) {
                    top: 6px;
                }

                span:nth-child(2) {
                    top: 16px;
                }

                span:nth-child(3) {
                    top: 26px;
                }
            }
        }

        &.-search {
            left: 35px;

            @screen m {
                left: 55px;
            }

            svg {
                position: absolute;
                width: 21px;
                height: 21px;
                left: 10px;
                top: 9px;

                @screen m {
                    width: 28px;
                    height: 28px;
                    top: 4px;
                    left: 7px;
                }
            }
        }

        &.-darkmode {
            left: 70px;

            @screen m {
                left: 100px;
            }

            svg {
                position: absolute;
                width: 21px;
                height: 21px;
                left: 10px;
                top: 9px;

                @screen m {
                    width: 28px;
                    height: 28px;
                    top: 4px;
                    left: 7px;
                }
            }
        }
    }

}
