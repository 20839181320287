.sticky-teaser {
    &.-variant-under {
        @screen mp {

        }
    }
    
    &.-variant-over {
        height: 0;
        padding-bottom: calc((7/5)*100%);
    }
    
    &.-variant-over-slim {
        height: 0;
        padding-bottom: calc((3/4)*100%);
    }

    &.-variant-over,
    &.-variant-over-slim {
        @screen mp {
            height: calc(100vh - 150px);
            min-height: 700px;
            padding-bottom: 0;
            overflow: hidden;
        }
    }
}
