.article-share-area {
    &__buttons {
        height: 38px;
        font-size: 0;
        line-height: 0;
    }

    &__button {
        width: 28px;
        height: 28px;
        margin: 0 3px;
        border-radius: 50%;
        transition: background-color 0.2s ease;
        top: 5px;

        @screen m {
            width: 32px;
            height: 32px;
            margin: 0 4px;
            top: 3px;
        }

        &.-facebook:hover {
            background-color: #4863b7;
        }

        &.-twitter:hover {
            background-color: #0992e3;
        }

        &.-large {
            width: 32px;
            height: 32px;
            margin: 0 4px;

            @screen m {
                width: 40px;
                height: 40px;
                margin: 0 6px;
            }
        }
    }

    svg {
        display: block;
        position: relative;
        height: 50%;
        width: 50%;
        left: 25%;
        top: 25%;
    }

    &__line {
        width: calc(50% - 44px);
        top: 50%;

        @screen m {
            width: calc(50% - 55px);
        }
    }
}
