.wysiwyg {
    p,
    blockquote,
    ul, 
    ol {
        margin-top: 1.75em;
        margin-bottom: 1.75em;

        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    
    blockquote {
        margin-top: 40px;
        margin-bottom: 40px;
        
        @screen l {
            margin-top: 60px;
            margin-bottom: 60px;
        }
        
        &:before {
            content: '”';
            display: block;
            font-size: 56px;
            line-height: 0.5;
            position: relative;
            top: 8px;
            
            @screen l {
                font-size: 64px;
            }
        }
    }
    
    i, em {
        font-style: italic;
    }
    
    strong, b {
        font-weight: 500;
    }
    
    h2, h3, h4, h5 {
        font-weight: 500;
        
        & + p {
            margin-top: 0;
        }
    }
    
    a {
        @apply link;
    }
    
    ul {
        list-style-type: disc;
        margin-left: 1em;
    }
    
    ol {
        list-style-type: decimal;
        margin-left: 1em;
    }
    
    li {
        display: list-item;
        position: relative;
        padding-left: 1em;
    }
}
